import _transition from "../../js/transition.js";
import _alert from "../../js/alert.js";
import _button from "../../js/button.js";
import _carousel from "../../js/carousel.js";
import _collapse from "../../js/collapse.js";
import _dropdown from "../../js/dropdown.js";
import _modal from "../../js/modal.js";
import _tooltip from "../../js/tooltip.js";
import _popover from "../../js/popover.js";
import _scrollspy from "../../js/scrollspy.js";
import _tab from "../../js/tab.js";
import _affix from "../../js/affix.js";
// This file is autogenerated via the `commonjs` Grunt task. You can require() this file in a CommonJS environment.
_transition;
_alert;
_button;
_carousel;
_collapse;
_dropdown;
_modal;
_tooltip;
_popover;
_scrollspy;
_tab;
_affix;
export default {};